<template>
  <v-dialog :value="insertInvoiceModal" persistent fullscreen>
    <v-card>
      <v-row no-gutters>
        <v-col cols="6">
          <input
            v-if="!sendMail"
            class="hidden"
            id="input"
            @focus="closeInsertInvoiceModal"
            type="text"
          />
          <v-card-title class="headline">Nieuwe factuur</v-card-title>
          <div class="settings">
            <label>Briefpapier:</label
            ><input
              @change="viewInvoice"
              type="checkbox"
              v-model="options.stationary"
            />
            <label><img src="/img/ideal.png" height="50" alt="ideal"/></label
            ><input
              @change="viewInvoice"
              type="checkbox"
              v-model="options.qrcode"
            />
            <label>Sjabloon:</label>
           <select @change="viewInvoice" v-model="options.invoiceTemplate">
             <option v-for="template of invoiceTemplates" :value="template.value" :key="template.text">{{template.text}}</option>
           </select>
            <template v-if="isElectron">
              <label>Printer:</label
              ><select v-model="options.printer"
                ><option
                  v-for="printer of printers"
                  :key="printer.name"
                  :value="printer.name"
                >
                  {{ printer.displayName }}
                </option>
              </select>
              <label>Aantal:</label
              ><input type="number" v-model="options.copies" />
              <label>Formaat:</label
              ><select v-model="options.pageSize"
                ><option
                  v-for="pageSize of pageSizes"
                  :key="pageSize.value"
                  :value="pageSize.value"
                >
                  {{ pageSize.text }}
                </option>
              </select>
            </template>
            <label>Aan:</label><input type="text" v-model="headers.to" />
            <label>Bericht:</label>
            <vue-editor
              v-if="sendMail"
              v-model="headers.html"
              :editor-toolbar="customToolbar"
            ></vue-editor>
            <a @click="sendMail = true" v-else>Klik hier om te bewerken</a>
          </div>
          <v-data-table
            v-model="invoice.orders"
            :headers="invoiceHeaders"
            :items="openOrders"
            item-key="_id"
            :footer-props="{
              'items-per-page-options': [-1, 10, 25, 50, 100]
            }"
            :items-per-page="50"
            show-select
            class="elevation-1"
            locale="nl"
          >
            <template v-slot:[`header.data-table-select`]="{ props, on }">
              <v-simple-checkbox
                :ripple="false"
                :value="props.value"
                @input="selectAllOrders(on, $event)"
              ></v-simple-checkbox>
            </template>
            <template
              v-slot:[`item.data-table-select`]="{ isSelected, select }"
            >
              <v-simple-checkbox
                :ripple="false"
                :value="isSelected"
                @input="selectOrder(select, $event)"
              ></v-simple-checkbox>
            </template>
            <template v-slot:[`item.date`]="{ item }">{{
              new Date(item.date).toLocaleDateString("nl-NL")
            }}</template>
            <template v-slot:[`item.name`]="{ item }">
              <a @click="openOrderModal(item)">{{ item.name }}</a>
            </template>
            <template v-slot:[`item.printed`]="{ item }">
              <a>
                <img v-if="item.printed" class="arrow" src="/img/printed.svg" />
                <img v-else class="arrow" src="/img/print.svg" />
              </a>
            </template>
            <template v-slot:[`item.emailed`]="{ item }">
              <a>
                <img v-if="item.emailed" class="arrow" src="/img/emailed.svg" />
                <img v-else class="arrow" src="/img/email.svg" />
              </a>
            </template>
            <template v-slot:[`item.status`]="{ item }">
              <v-img
                :src="`/img/${item.status}.svg`"
                :alt="`${item.status}`"
                width="25px"
              ></v-img>
            </template>
            <template v-slot:[`item.grossTotal`]="{ item }">
              <div>{{ toCurrency(item.grossTotal) }}</div>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="6">
          <iframe :src="dataUrl" frameborder="0" id="iframe"></iframe>
        </v-col>
      </v-row>
      <v-btn-toggle class="d-flex justify-center">
        <v-btn color="primary" @click="closeInsertInvoiceModal">Annuleer</v-btn>
        <v-btn
          color="primary"
          @click="print"
          :disabled="!this.invoice.orders.length"
          >Afdrukken</v-btn
        >
        <v-btn
          color="primary"
          @click="email"
          :disabled="!this.invoice.orders.length"
          >Mailen</v-btn
        >
        <v-btn
          color="primary"
          @click="save"
          :disabled="!this.invoice.orders.length"
          >Opslaan</v-btn
        >
      </v-btn-toggle>
    </v-card>
  </v-dialog>
</template>
<script>
import { VueEditor } from "vue2-editor"
import { config } from "../config/config.mjs"
import { emailInvoice, viewInvoice, printInvoice } from "../js/pdfController"
import {
  createInvoice,
  errorHandler,
  fetchGET,
  fetchPOST,
  insertInvoice,
  // emailInvoice,
  successHandler,
  toCurrency
} from "../js/functions"
export default {
  props: {
    insertInvoiceModal: Boolean,
    singleOrder: Boolean,
    newOrder: Boolean,
    order: Object,
    selectedOrders: Array,
    client: Object
  },
  data() {
    return {
      openOrders: [],
      sendMail: false,
      invoice: {
        invoiceNumber: 0,
        client: {},
        clientNumber: 1,
        date: new Date(Date.now()),
        dueDate: new Date(Date.now()),
        netTotal: 0,
        grossTotal: 0,
        totalVAT: 0,
        credit: 0,
        debit: 0,
        orders: [],
        payments: [],
        VAT: []
      },
      previewModal: false,
      buffer: "",
      dataUrl: "",
      iframeLoaded: false,
      pageSizes: [
        { value: "A4", text: "A4" },
        { value: "A5", text: "A5" }
      ],
      invoiceHeaders: [
        { text: "", value: "data-table-select" },
        { text: "Status", value: "status" },
        { text: "Ordernr", value: "orderNumber" },
        { text: "Naam", value: "name" },
        { text: "Datum", value: "date" },
        { text: "Totaal", value: "grossTotal" },
        { text: "Print", value: "printed" },
        { text: "Email", value: "emailed" }
      ],
      headers: {
        subject: "",
        fileName: "",
        to: ""
      },
      options: {
        qrcode: true,
        pageSize: "A4",
        stationary: false,
        copies: 1,
        printer: "",
        invoiceTemplate: null
      },
      customToolbar: [[{ header: [1, 2, 3, 4, 5, 6, false] }], ["bold"]]
    }
  },
  methods: {
    toCurrency(val) {
      return toCurrency(val)
    },
    selectOrder(select, event) {
      select(event)
      setTimeout(async () => {
        this.invoice.orders = this.invoice.orders.sort(
          (a, b) => a.orderNumber - b.orderNumber
        )
        await this.viewInvoice()
      }, 100)
    },
    selectAllOrders(on, event) {
      on.input(event)
      setTimeout(async () => {
        this.invoice.orders = this.invoice.orders.sort(
          (a, b) => a.orderNumber - b.orderNumber
        )
        await this.viewInvoice()
      }, 100)
    },
    iframeOnload() {
      let iframe = document.getElementById("iframe")
      iframe.removeEventListener("load", this.iframeOnload)
      if (
        iframe.contentDocument.readyState === "complete" &&
        this.iframeLoaded
      ) {
        this.printInvoice()
      } else {
        var that = this
        setTimeout(function() {
          return that.iframeOnload(iframe)
        }, 1000)
      }
    },
    async print() {
      if (this.newOrder) {
        let i = 0
        let len = this.invoice.orders.length
        for (i; i < len; i++) {
          if (!this.invoice.orders[i]._id) {
            let result = await fetchPOST("insertOrder", this.invoice.orders[i])
            this.invoice.orders[i] = result.ops[0]
          }
        }
      }
      this.invoice = createInvoice(this.invoice, true)
      this.invoice = await insertInvoice(this.invoice)
      // await emailInvoice(this.invoice,{
      //   stationary: false,
      //   qrcode: false,
      //   reminderType: 0,
      //   reminderTypes:[
      //   {status:0,text:"Geen"},
      //   {status:1,text:"Herinnering"},
      //   {status:2,text:"Aanmaning"}
      // ],
      //   invoiceTemplate: null
      // },)
      if (this.isElectron) {
        this.printInvoice()
      } else {
        document
          .getElementById("iframe")
          .addEventListener("load", this.iframeOnload)
        await this.viewInvoice(true)
        this.$store.commit("insertInvoice", this.invoice)
        this.iframeLoaded = true
        this.sendMail = false
      }
    },
    async printInvoice() {
      localStorage.setItem("printInvoiceOptions", JSON.stringify(this.options))
      if (this.isElectron) {
        let options = {
          printer: this.options.printer,
          paperSize: this.options.pageSize,
          copies: this.options.copies,
          scale: "fit",
          silent: true
        }
        this.buffer = await printInvoice(this.invoice, this.options)
        window.ipcRenderer.send("printPdfBuffer", {
          buffer: this.buffer,
          options: options
        })
      } else document.getElementById("iframe").contentWindow.print()
      setTimeout(() => {
        let input = document.getElementById("input")
        input.focus()
      }, 1000)
    },
    async viewInvoice(insert) {
      if (!insert) this.invoice = createInvoice(this.invoice)
      let data = await viewInvoice(this.invoice, this.options)
      this.dataUrl = URL.createObjectURL(data)
    },
    async email() {
      try {
        if (this.newOrder) {
          let i = 0
          let len = this.invoice.orders.length
          for (i; i < len; i++) {
            if (!this.invoice.orders[i]._id) {
              let result = await fetchPOST(
                "insertOrder",
                this.invoice.orders[i]
              )
              this.invoice.orders[i] = result.ops[0]
            }
          }
        }
        this.options.stationary = false
        this.invoice = createInvoice(this.invoice, false, true)
        this.invoice = await insertInvoice(this.invoice)
        this.$store.commit("insertInvoice", this.invoice)
        this.closeInsertInvoiceModal()
        if (this.options.qrcode) {
          this.headers.html += `<br><br>Betaal nu gemakkelijk online<br><img src='${config.app.host}/img/ideal.png'/><br>${config.app.host}/createPayment/${this.invoice._id}`
        } else
          this.headers.html = this.headers.html.replace(
            `<br><br>Betaal nu gemakkelijk online<br><img src='${config.app.host}/img/ideal.png'/><br>${config.app.host}/createPayment/${this.invoice._id}`,
            ""
          )
        let buffer = await emailInvoice(this.invoice, this.options)
        this.headers.subject = `Factuur ${this.invoice.invoiceNumber}`
        this.headers.fileName = `Factuur ${this.invoice.invoiceNumber}.pdf`
        let response = await fetchPOST("sendMail", {
          buffer: buffer,
          headers: this.headers
        })
        console.log(response)
        if (response.success) successHandler("Email verzonden")
        else throw "emailInvoice"
      } catch (e) {
        errorHandler(e, "Email niet verzonden")
      }
    },
    async save() {
      try {
        if (this.newOrder) {
          let i = 0
          let len = this.invoice.orders.length
          for (i; i < len; i++) {
            if (!this.invoice.orders[i]._id) {
              let result = await fetchPOST(
                "insertOrder",
                this.invoice.orders[i]
              )
              this.invoice.orders[i] = result.ops[0]
            }
          }
        }
        this.invoice = createInvoice(this.invoice)
        this.invoice = await insertInvoice(this.invoice)
        console.log("after invoice")
        this.$store.commit("insertInvoice", this.invoice)
        successHandler("Factuur opgeslagen")
        this.closeInsertInvoiceModal()
      } catch (e) {
        errorHandler(e, "Factuur niet opgeslagen")
      }
    },
    closeInsertInvoiceModal() {
      if (this.invoice._id) this.$emit("add-invoice-payments", this.invoice)
      URL.revokeObjectURL(this.dataUrl)
      this.$emit("close-insert-invoice-modal")
    }
  },
  computed: {
    isElectron() {
      return this.$store.state.isElectron
    },
    printers() {
      return this.$store.state.printers
    },
    invoiceTemplates(){
      return this.$store.state.settings.invoiceTemplates
    }
  },
  async mounted() {
    if (JSON.parse(localStorage.getItem("printInvoiceOptions")))
      this.options = JSON.parse(localStorage.getItem("printInvoiceOptions"))
    if (this.singleOrder) {
      this.invoice.orders.push(this.order)
      this.invoice.client = await fetchGET(
        "findClient",
        this.order.clientNumber
      )
    } else {
      this.invoice.orders = this.selectedOrders
      this.invoice.client = await fetchGET(
        "findClient",
        this.$store.state.selectedClient.clientNumber
      )
    }
    this.openOrders = await fetchGET(
      "openOrders",
      this.invoice.client.clientNumber
    )
    this.options.qrcode = this.$store.state.settings.ideal.enabled? true: false
    this.options.invoiceTemplate = this.$store.state.settings.invoiceTemplate
    await this.viewInvoice()
    this.headers = {
      ...this.$store.state.settings.emailInvoiceBody,
      ...this.headers
    }
    this.headers.to = this.invoice.client.email
  },
  components: { VueEditor }
}
</script>
<style scoped>
table {
  width: 500px;
  margin: auto;
}
div.settings {
  justify-content: space-evenly;
  font-size: 2em;
  display: grid;
  grid-template-columns: min-content min-content;
  grid-gap: 5px;
  margin-bottom: 75px;
}
.quillWrapper {
  height: 300px;
}
input[type="checkbox"] {
  height: 1em;
  width: 1em;
}
#iframe {
  height: 700px;
  width: 600px;
  margin: auto;
  display: block;
}
.arrow {
  width: 25px;
}
.hidden {
  width: 0;
  height: 0;
  overflow: hidden;
}
</style>